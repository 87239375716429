import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import Headline from '../components/headline'
import { Box, Flex, Heading, Text, Link as RawLink } from 'rebass'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../components/container'
import YTranslationsLogo from '../components/icons/ytranslations-logo'
import Translation from '../components/icons/translation'
import Subtitles from '../components/icons/subtitles'
import CountUp from 'react-countup'
import CheckboxTicked from '../components/icons/checkbox-ticked'
import Hr from '../components/hr'
import Parallax from '../components/parallax'
import Paragraph from '../components/paragraph'

function MyCell({ heading, children }) {
  return (
    <Box maxWidth="500px">
      <Heading as="h3" fontSize={5} mb={3} fontWeight="lighter">
        {heading}
      </Heading>
      <Text fontSize={3}>{children}</Text>
    </Box>
  )
}

const Done = props => (
  <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
    <Text flex="1" textAlign="center">
      {props.children}
    </Text>{' '}
    <CheckboxTicked size={50} color="gold" flexShrink="0" />
  </Box>
)

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      headerBackgroundImage: file(relativePath: { eq: "nice-pic-of-isr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quoteBackgroundImage: file(relativePath: { eq: "cafe-with-earpods.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About" />
      <Headline fluidBackgroundImage={data.headerBackgroundImage.childImageSharp.fluid}>
        <Heading variant="headline" as="h1">
          About my work
        </Heading>
        <Hr color="gold" />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '80px 1fr'],
            gridGap: '1rem',
            placeItems: 'center',
          }}
        >
          <Translation color="gold" size={80} />
          <Text fontWeight="lighter" fontSize={[3, null, 5]} sx={{ justifySelf: 'flex-start' }}>
            <em>
              <CountUp end={435000} separator="," useEasing={false} />
            </em>
            &nbsp;words translated
          </Text>
          <Subtitles color="gold" size={50} />
          <Text fontWeight="lighter" fontSize={[3, null, 5]} sx={{ justifySelf: 'flex-start' }}>
            <em>
              <CountUp end={2400} separator="," useEasing={false} />
            </em>
            &nbsp;minutes subtitled
          </Text>
        </Box>
      </Headline>
      <Container my={5} narrow>
        <Heading as="h2" textAlign="center" mb={4} mx={[4, 5]} fontSize={[4, 5]}>
          Hello, my name is Idunn and I run a London-based translation company.
        </Heading>
        <Text as="article" variant="article">
          <Paragraph>
            I work with clients to get their message across to the Norwegian audience. Whether you’re telling a story,
            selling a product or offering a service; localising your content will make sure nothing gets lost in
            translation.
          </Paragraph>

          <Paragraph emphasize>
            I can help you tailor your message to make it connect with the culture of the Norwegian people, in their
            colloquial or formal language – whichever suits your subject matter!
          </Paragraph>
          <Paragraph>
            I founded <em>ISR Translations Ltd.</em> in 2018 and have very much enjoyed working with a variety of
            clients since. I've worked on localising websites for large companies looking to expand into the Norwegian
            territory, and translated a non-fiction book for its publication in Norway.
          </Paragraph>
          <Paragraph emphasize>
            I’ve also created subtitles for some of the biggest production companies in the world and provided quality
            control services for the last stages of dubbing processes.
          </Paragraph>
          <Paragraph>
            I offer a personal translation service where I speak directly to my end-clients, which allows me to really
            understand their needs and also speed up the communication process.
          </Paragraph>
          <Paragraph>
            Whether I’m working with big or small companies, I know how important it is for them to come out of the
            collaboration with a quality product and a positive impression. I know I don’t spend my company’s funds on
            things I don’t care about – so why would you?
          </Paragraph>
          <Paragraph emphasize>
            I always strive for my collaborations to land on the fine line between high quality results and efficient
            delivery.
          </Paragraph>
          <Hr color="gold" mt={3} />
        </Text>
      </Container>

      <Parallax fluid={data.quoteBackgroundImage.childImageSharp.fluid}>
        <Flex
          flexDirection="column"
          alignItems="center"
          px={[4, null, 6]}
          py={[6]}
          color="white"
          backgroundColor="rgba(0, 0, 0, 0.5)"
        >
          <RawLink color="white" href="https://www.ytranslations.com/">
            <YTranslationsLogo width={300} mb={4} />
          </RawLink>
          <Heading fontSize={[4, null, 5]} fontWeight="lighter" textAlign={'center'}>
            <em>
              Idunn has done a great job for us and we will definitely assign more projects to her in the future! Should
              you need a reliable Norwegian linguist to complete translations, revisions or subtitling, you can count on
              her.
            </em>
          </Heading>
          <Hr center />
          <Text textAlign="center" fontSize={[3, null, 4]} lineHeight="1.5">
            OANA CHISALITA
            <br /> Senior Project Manager
          </Text>
        </Flex>
      </Parallax>

      <Box backgroundColor="soft-black" color="white">
        <Container display="flex" justifyContent="center">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gridGap: '5rem 5rem',
              textAlign: 'center',
              py: '4rem',
            }}
          >
            <MyCell heading="My Education">
              <Done>Bachelor of Arts in English Literature</Done>
              <Done>Trade Degree in Publishing</Done>
            </MyCell>
            <MyCell heading="My Experience">
              <Done>2 years running a translation company</Done>
              <Done>Trusted by Fortune 500 companies</Done>
              <Done>Subtitling for major production companies</Done>
              <Done>3 years in the publishing industry</Done>
            </MyCell>
            <MyCell heading="My Approach">
              <Done>Clear and speedy communication</Done>
              <Done>Personal and friendly service</Done>
              <Done>Reliable data security routines</Done>
            </MyCell>
            <MyCell heading="Your Final Results">
              <Done>Timely deliveries</Done>
              <Done>Top-notch quality</Done>
              <Done>Post-completion support</Done>
            </MyCell>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}
