import React from 'react'
import { Box } from 'rebass'

export default function YTranslationsLogo({ width = 400, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 400 130"
      width={width}
      height={width * 0.325}
      fill="currentcolor"
      {...props}
    >
      <title>YTranslations Logo</title>
      <path
        d="M117.231,81.797h-7.655v-9.549L98.372,51.536h7.702l7.309,13.184l7.137-13.184h7.697l-10.985,20.497V81.797z
      M146.739,57.721v24.076h-7.691V57.721h-7.142l-3.624-6.23h27.583v6.23H146.739z M165.802,62.434
      c1.598-2.723,4.235-4.24,7.569-4.286v6.755c-4.327-0.397-7.182,1.639-7.569,4.927v11.967h-7.483V58.495h7.483V62.434z
      M196.755,66.588v15.209h-7.305v-2.672c-1.516,1.984-3.985,3.018-7.141,3.018c-4.973,0-7.997-3.018-7.997-7.293
      c0-4.414,3.151-7.01,8.948-7.055h6.144v-0.346c0-2.287-1.512-3.676-4.541-3.676c-1.95,0-4.495,0.697-6.963,1.95l-2.123-4.932
      c3.634-1.69,6.923-2.642,10.812-2.642C192.993,58.148,196.715,61.31,196.755,66.588z M189.405,73.893V71.82h-4.713
      c-2.25,0-3.38,0.779-3.38,2.504c0,1.69,1.211,2.77,3.293,2.77C186.941,77.094,188.932,75.797,189.405,73.893z M225.846,67.103
      v14.694h-7.432V69.18c0-2.725-1.604-4.409-4.103-4.409c-2.729,0-4.464,1.945-4.765,4.708v12.318h-7.488V58.495h7.488v3.808
      c1.64-2.729,4.327-4.108,7.788-4.154C222.487,58.148,225.846,61.656,225.846,67.103z M249.108,60.791l-2.555,4.846
      c-2.81-1.518-5.406-2.337-7.351-2.337c-1.344,0-2.291,0.514-2.291,1.604c0,3.196,12.277,1.639,12.237,9.772
      c0,4.795-4.194,7.426-9.824,7.426c-3.879,0-7.646-1.113-10.455-3.277l2.372-4.76c2.637,1.863,5.67,2.902,8.256,2.902
      c1.477,0,2.556-0.52,2.556-1.599c0-3.421-12.063-1.648-12.023-9.819c0-4.799,4.072-7.4,9.468-7.4
      C242.877,58.148,246.257,59.101,249.108,60.791z M253.546,81.797V49.719h7.483v32.078H253.546z M287.833,66.588v15.209h-7.31v-2.672
      c-1.506,1.984-3.97,3.018-7.136,3.018c-4.968,0-8.001-3.018-8.001-7.293c0-4.414,3.155-7.01,8.958-7.055h6.149v-0.346
      c0-2.287-1.527-3.676-4.552-3.676c-1.943,0-4.499,0.697-6.963,1.95l-2.127-4.932c3.634-1.69,6.932-2.642,10.821-2.642
      C284.077,58.148,287.793,61.31,287.833,66.588z M280.494,73.893V71.82h-4.724c-2.251,0-3.38,0.779-3.38,2.504
      c0,1.69,1.211,2.77,3.287,2.77C278.02,77.094,280.005,75.797,280.494,73.893z M308.195,80.551c-1.69,0.992-3.98,1.592-6.23,1.592
      c-4.5,0-7.747-2.539-7.747-7.381V64.47h-3.237v-4.896h3.237v-6.485h7.452v6.45h6.088v4.932h-6.088v9.122
      c0,1.816,0.773,2.596,2.158,2.551c0.773,0,1.771-0.301,2.901-0.774L308.195,80.551z M319.637,51.791c0,2.342-1.639,3.985-3.93,3.985
      c-2.29,0-3.939-1.644-3.939-3.985c0-2.286,1.649-3.935,3.939-3.935C317.998,47.856,319.637,49.505,319.637,51.791z M311.941,81.797
      V58.495h7.442v23.302H311.941z M348.915,70.137c0,7.176-5.11,11.926-12.715,11.926c-7.655,0-12.766-4.75-12.766-11.926
      c0-7.183,5.11-11.891,12.766-11.891C343.805,58.246,348.915,62.954,348.915,70.137z M330.957,70.223
      c0,3.629,2.128,6.053,5.243,6.053c3.064,0,5.192-2.424,5.192-6.053c0-3.594-2.128-6.062-5.192-6.062
      C333.085,64.16,330.957,66.629,330.957,70.223z M376.708,67.103v14.694h-7.442V69.18c0-2.725-1.598-4.409-4.103-4.409
      c-2.729,0-4.459,1.945-4.754,4.708v12.318h-7.492V58.495h7.492v3.808c1.639-2.729,4.326-4.108,7.778-4.154
      C373.338,58.148,376.708,61.656,376.708,67.103z M399.959,60.791l-2.555,4.846c-2.811-1.518-5.406-2.337-7.35-2.337
      c-1.344,0-2.291,0.514-2.291,1.604c0,3.196,12.287,1.639,12.236,9.772c0,4.795-4.194,7.426-9.813,7.426
      c-3.889,0-7.655-1.113-10.466-3.277l2.372-4.76c2.646,1.863,5.671,2.902,8.267,2.902c1.466,0,2.546-0.52,2.546-1.599
      c0-3.421-12.064-1.648-12.023-9.819c0-4.799,4.072-7.4,9.478-7.4C393.73,58.148,397.11,59.101,399.959,60.791z M64.436,96.186
      H19.017l3.863-3.869h37.693l-4.571-4.57H27.451l3.863-3.869h20.824l-4.566-4.561H35.876l2.031-2.041
      c19.541-17.511,28.032-30.603,29.849-47.104c0-0.509-0.412-0.917-0.921-0.917h-9.676c-0.514,0-0.927,0.407-0.927,0.917
      c-0.208,1.506-0.493,2.973-0.85,4.407H16.111c0.417,1.538,0.946,3.054,1.578,4.572H53.95c-0.504,1.312-1.089,2.626-1.751,3.939
      H19.607c0.84,1.507,1.807,3.024,2.901,4.572h27.064c-0.84,1.292-1.761,2.606-2.779,3.939H25.548c1.221,1.477,2.57,2.993,4.057,4.572
      h13.418c-2.591,2.932-5.63,6.006-9.142,9.305c-14.4-13.469-20.691-23.231-22.356-35.305c0-0.509-0.413-0.917-0.921-0.917H0.962
      c-0.509,0-0.921,0.407-0.921,0.917H0c1.685,15.26,9.086,27.609,25.639,43.226L0.448,98.598c-0.698,0.703-0.56,2.148,0.855,2.148
      c1.461,0,4.815,0,7.768,0c2.881,0,5.38,0,5.38,0h54.551L64.436,96.186z"
      />
    </Box>
  )
}
