import React from 'react'
import { Box } from 'rebass'

export default function CheckboxTicked({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <title>Ticked checkbox</title>
      <polygon points="77.8,27.8 48,57.6 36.6,45.3 35.1,46.6 47.9,60.5 79.2,29.2 " />
      <path d="M64.2,23v-2H21.8c-0.6,0-1,0.4-1,1v56c0,0.6,0.4,1,1,1h56c0.6,0,1-0.4,1-1V48.1h-2V77h-54V23H64.2z" />
    </Box>
  )
}
